export const TempusProjectForTimesheet = () => ({
  comboboxType: "timesheets",
  nextElementId: "combobox-users",
  close: true,
  initProject() {
    this.fetchProjectsForTimesheets()
    this.projectSelectedTimesheet = {
      key: 0,
      displayName: "Alle",
      name: "Alle",
      value: "Alle",
      id: 0
    }
  },

  setTimesheet(projectKey) {
    this.projectSelectedTimesheet = this.timesheets[projectKey]
    this.projectIssuesTimesheet = []
    this.projectWorklogsTimesheet = []
    this.projectWorklogsTimesheetTmp = []
    document.getElementById("combobox-timesheets").value =
      this.projectSelectedTimesheet.key +
      "- " +
      this.projectSelectedTimesheet.name
  },

  fetchProjectsForTimesheets(projects = []) {
    if (projects.length == 0) {
      this.itemlists["timesheets"] = [0]
      this.timesheets = {
        0: {
          key: 0,
          displayName: "Alle",
          name: "Alle",
          value: "Alle",
          id: 0
        }
      }
    }
    this.setLoading(true)
    this.fetchGETSomething(
      "/project/search",
      "&maxResults=50&startAt=" + projects.length + "&status=live"
    )
      .then((result) => {
        if (result.values) {
          projects = projects.concat(result.values)
        }
        if (result.total > projects.length) {
          return this.fetchProjectsForTimesheets(projects)
        } else {
          for (const value of projects) {
            this.timesheets[value.key] = value
            this.itemlists["timesheets"].push(value.key)
          }
        }
        return projects
      })
      .then(() => {
        this.setLoading(false)
        this.itemlistsActive["timesheets"] = this.itemlists["timesheets"]
      })
      .catch((err) => {
        this.setLoading(false)
        console.log(err)
      })
  },

  resetTimesheet() {
    this.projectIssuesTimesheet = []
    this.projectWorklogsTimesheet = []
    this.projectWorklogsTimesheetTmp = []
    if (document.getElementById("dateFrom").value != "") {
      this.timesheetDateFrom = document.getElementById("dateFrom").value
    }
    if (document.getElementById("dateTo").value != "") {
      this.timesheetDateTo = document.getElementById("dateTo").value
    }
  },

  getIssues(issues = []) {
    const max = 100
    const startedAfterDate = this.timesheetDateFrom
    const startedBeforeDate = this.timesheetDateTo
    let worklogDateFrom = ""
    let worklogDateTo = ""
    if (startedAfterDate != "") {
      const startedAfter = Math.floor(new Date(startedAfterDate).getTime())
      worklogDateFrom = " worklogDate >= " + startedAfter
    }
    if (startedBeforeDate != "") {
      const startedBefore =
        Math.floor(new Date(startedBeforeDate).getTime()) + 24 * 3600000
      worklogDateTo = " and worklogDate <= " + startedBefore
    }
    let jqlForState = ""
    if (this.$store.tempus.fetchDoneOnly) {
      jqlForState = " resolution = Done and "
    }

    let jqlForCustomer = ""
    if (
      this.customerSelectedForTimesheet &&
      this.customerSelectedForTimesheet.key != 0 &&
      this.customerSelectedForTimesheet.key != undefined
    ) {
      jqlForCustomer =
        " cf[10068] = " + this.customerSelectedForTimesheet.id + " and "
    }
    let jqlForProject = ""
    if (
      this.projectSelectedTimesheet &&
      this.projectSelectedTimesheet.hasOwnProperty("key") &&
      this.projectSelectedTimesheet.key != 0
    ) {
      jqlForProject =
        " project  = " + this.projectSelectedTimesheet.key + " and "
    }
    const data = {
      expand: [],
      fields: [
        "parent",
        "summary",
        "issuetype",
        "customfield_10044",
        this.customerCustomfield
      ],
      fieldsByKeys: false,
      jql:
        jqlForCustomer +
        jqlForState +
        jqlForProject +
        worklogDateFrom +
        worklogDateTo,
      maxResults: max,
      startAt: issues.length
    }
    this.setLoading(true)
    this.fetchPOSTPUTSomething("POST", "/search", data)
      .then((result) => {
        if (result.issues) {
          issues.push(...result.issues)
        }
        if (result.total > issues.length) {
          return this.getIssues(issues)
        } else {
          issues.sort((a, b) => {
            const customerA = a.fields[this.customerCustomfield]
              ? a.fields[this.customerCustomfield].value
              : ""
            const customerB = b.fields[this.customerCustomfield]
              ? b.fields[this.customerCustomfield].value
              : ""
            if (customerA === customerB) {
              const summaryA =
                a.fields.parent &&
                a.fields.parent.fields &&
                a.fields.parent.fields.summary
                  ? a.fields.parent.fields.summary
                  : ""
              const summaryB =
                b.fields.parent &&
                b.fields.parent.fields &&
                b.fields.parent.fields.summary
                  ? b.fields.parent.fields.summary
                  : ""
              return summaryB.localeCompare(summaryA)
            }
            return 0
          })
          this.projectIssuesTimesheet = issues
          return issues
        }
      })
      .then(() => {
        this.setLoading(false)
        this.getWorklogsForIssuesOnTimesheets(this.projectIssuesTimesheet)
        //console.log(this.projectIssuesTimesheet)
      })
      .catch((err) => {
        this.setLoading(false)
        console.log(err)
      })
  },

  async getWorklogsForIssuesOnTimesheets(issues) {
    const startedAfterDate = this.timesheetDateFrom
    const startedBeforeDate = this.timesheetDateTo
    // queryByDate still needed
    let queryByDate = ""
    if (startedAfterDate != "") {
      const startedAfter = Math.floor(new Date(startedAfterDate).getTime())
      queryByDate += "&startedAfter=" + startedAfter
    }
    if (startedBeforeDate != "") {
      const startedBefore =
        Math.floor(new Date(startedBeforeDate).getTime()) + 24 * 3600000
      queryByDate += "&startedBefore=" + startedBefore
    }
    let eachIssuesCounter = 0
    for (const issue of issues) {
      await this.collectWorklogsForTimesheets(issue.key, issue, queryByDate)
    }
    this.sortIssues()
    this.projectWorklogsTimesheet = this.projectWorklogsTimesheetTmp
  },

  sortIssues() {
    this.projectWorklogsTimesheetTmp.sort((a, b) => {
      const aCustomer =
        a.fields.hasOwnProperty(this.customerCustomfield) &&
        a.fields[this.customerCustomfield] != null
          ? parseInt(a.fields[this.customerCustomfield].value.split("::")[1])
          : 0
      const bCustomer =
        b.fields.hasOwnProperty(this.customerCustomfield) &&
        b.fields[this.customerCustomfield] != null
          ? parseInt(b.fields[this.customerCustomfield].value.split("::")[1])
          : 0
      if (aCustomer >= bCustomer) {
        return -1
      } else {
        return 1
      }
    })
  },

  async collectWorklogsForTimesheets(
    issueKey,
    issue,
    queryByDate,
    worklogs = [],
    count = 0
  ) {
    const max = 100
    const endpoint = "/issue/" + issueKey + "/worklog/?expand=properties"
    const query =
      "&maxResults=" + max + "&startAt=" + worklogs.length + queryByDate
    this.setLoading(true)
    await this.fetchGETSomething(endpoint, query)
      .then((result) => {
        this.setLoading(false)
        count += result.worklogs.length
        let worklogstmp = result.worklogs
        if (this.$store.tempus.fetchNotChargedOnly) {
          worklogstmp = worklogstmp.filter(
            (worklog) => !(this.getWorklogProperty(worklog, "charged", 0) > 0)
          )
        }
        if (
          this.userSelectedForTimesheet &&
          this.userSelectedForTimesheet.key != "Alle"
        ) {
          worklogstmp = worklogstmp.filter(
            (worklog) =>
              worklog.author.displayName == this.userSelectedForTimesheet.key
          )
        }
        worklogs.push(...worklogstmp)
        if (result.total > count) {
          return this.collectWorklogsForTimesheets(
            issueKey,
            issue,
            queryByDate,
            worklogs,
            count
          )
        } else {
          issue["worklogs"] = worklogs
          this.projectWorklogsTimesheetTmp.push(...[issue])
        }
      })
      .catch((err) => {
        this.setLoading(false)
        console.log(err)
      })
  }
})

export const TempusIssue = () => ({
  comboboxType: "issues",
  nextElementId: "comment-active-tracking",
  close: true,

  fetchIssueData(id_or_key) {
    this.setLoading(true)
    this.fetchGETSomething("/issue/" + id_or_key + "?expand=transitions")
      .then((result) => {
        this.setLoading(false)
        if (result.err) {
          window.location.reload()
        }
        this.issueSelected = result
        this.issueSelectedData = result
        this.issues[result["key"]] = result
        if (
          this.projectSelected.id == null ||
          this.projectSelected.key !== result.fields.project.key
        ) {
          this.setProject(result.fields.project.key)
          this.$nextTick(() => this.fetchIssues(this.projectSelected.id))
        }
      })
      .catch((err) => {
        this.setLoading(false)
        console.log(err)
      })
  },

  setIssueStatus($event, issueKey) {
    if ($event.target.value != 0) {
      this.setLoading(true)
      this.fetchPOSTPUTSomething(
        "POST",
        "/issue/" + issueKey + "/transitions",
        {
          transition: { id: $event.target.value }
        }
      )
        .then(() => {
          this.setLoading(false)
          this.setLocalIssue($event.target.value)
        })
        .catch((err) => {
          this.setLoading(false)
          console.log(err)
        })
    }
  },

  setLocalIssue(transitionValue) {
    this.issueSelectedData["transitions"].forEach((transition) => {
      if (transition["id"] == transitionValue) {
        this.issueSelectedData["fields"]["status"] = transition["to"]
      }
    })
  },

  setIssue(issueKey) {
    if (
      this.activeWorklog !== null &&
      this.activeWorklog.issueKey != issueKey
    ) {
      this.setTrackingStatus()
    }
    this.fetchIssueData(issueKey)

    if (document.querySelector("#" + issueKey)) {
      document.querySelector("#combobox-issues").value = document.querySelector(
        "#" + issueKey
      ).dataset.name
    } else {
      document.querySelector("#combobox-issues").value = null
      document.querySelector("#combobox-projects").value = null
    }
    return true
  },

  fetchIssues(id, issues = []) {
    const max = 100
    let total = 0
    if (id !== null) {
      let query =
        "&fields=customfield_10044,summary,issuetype,priority,duedate,parent,labels,status&maxResults=" +
        max +
        "&startAt=" +
        issues.length +
        "&jql=" +
        encodeURIComponent("project = " + id)
      if (this.$store.tempus.viewNoDoneIssues) {
        query += encodeURIComponent(" AND resolution = Unresolved")
      }
      if (this.$store.tempus.viewOnlyMyIssues) {
        query += encodeURIComponent(
          ' AND assignee = "' + this.user.displayName + '"'
        )
      }
      query += encodeURIComponent(" AND type != epic")
      query += encodeURIComponent(" order by updated ASC")
      this.setLoading(true)
      this.fetchGETSomething("/search", query)
        .then((result) => {
          if (total == 0) {
            total = result.total
          }
          issues.push(...result.issues)
          if (result.total > issues.length) {
            return this.fetchIssues(id, issues)
          } else {
            return issues
          }
        })
        .then((issues) => {
          this.setLoading(false)
          if (issues) {
            issues.sort((a, b) => {
              if (!("parent" in a.fields)) {
                return -1
              } else if (!("parent" in b.fields)) {
                return 1
              }
              return (a.fields.parent.key > b.fields.parent.key && -1) || 1
            })
            this.issues = {}
            this.itemlists["issues"] = []
            for (const issue of issues) {
              this.issues[issue.key] = issue
              this.itemlists["issues"].push(issue.key)
            }
            this.itemlistsActive["issues"] = this.itemlists["issues"]
          }
        })
        .catch((err) => {
          this.setLoading(false)
          console.error(err)
        })
    }
  },
  toggleViewOnlyMyIssues() {
    this.$store.tempus.viewOnlyMyIssues = !this.$store.tempus.viewOnlyMyIssues
    if (this.projectSelected) {
      this.fetchIssues(this.projectSelected.id)
    }
  },
  toggleViewNoDoneIssues() {
    this.$store.tempus.viewNoDoneIssues = !this.$store.tempus.viewNoDoneIssues
    if (this.projectSelected) {
      this.fetchIssues(this.projectSelected.id)
    }
  },
  addBookmark(issueKey, issueData) {
    const issue = this.issues[issueKey] || issueData
    if (!issue) {
      this.$dispatch("toggle-notification", {
        message: "Task nicht gefunden",
        type: "error"
      })
      return
    }
    const bookmark = { key: issue.key, summary: issue.fields.summary }

    const exists = this.bookmarkIssues.find((b) => b.key === bookmark.key)
    if (exists) {
      this.$dispatch("toggle-notification", {
        message: "Task ist bereits in der Merkliste",
        type: "info"
      })
      return
    }

    if (this.bookmarkIssues.length >= 5) {
      this.$dispatch("toggle-notification", {
        message: "Merkliste ist bereits voll (5)",
        type: "warning"
      })
      return
    }

    this.bookmarkIssues.push(bookmark)
    this.$dispatch("toggle-notification", {
      message: "Task in der Merkliste gespeichert",
      type: "success"
    })
  },

  deleteBookmark(issueKey) {
    this.bookmarkIssues = this.bookmarkIssues.filter((b) => b.key !== issueKey)
    this.$dispatch("toggle-notification", {
      message: "Task aus der Merkliste entfernt",
      type: "success"
    })
  }
})
